import { NbDialogRef } from "@nebular/theme";
import { Component, Input } from "@angular/core";

export interface ConfirmationPopupConfigrations {
  message: string;
  cancelBtnText: string;
  confirmBtnText: string;
}

@Component({
  selector: "ngx-save-button",
  template: `
    <nb-card class="p-4">
      <div class="d-flex mb-3">
        <div class="flex-grow-1">
          <span style="font-size:24px;font-weight: 600">{{
            config.message
          }}</span>
        </div>
      </div>

      <div class="d-flex flex-row-reverse">
        <button
          class="ml-3"
          style="background: #FFFFFF;color: #192A41;border-color:#192A41;"
          nbButton
          status="success"
          (click)="cancel()"
        >
          {{ config.cancelBtnText }}
        </button>
        <button
          style="background: #192A41; border-color:#192A41;color: white"
          class="cancel "
          nbButton
          status="danger"
          (click)="confirm()"
        >
          {{ config.confirmBtnText }}
        </button>
      </div>
    </nb-card>
  `,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmationPopup {
  @Input() config: ConfirmationPopupConfigrations = {
    message: "Are you sure want to delete?",
    confirmBtnText: "Yes",
    cancelBtnText: "No",
  };

  constructor(protected ref: NbDialogRef<ConfirmationPopup>) {}

  cancel() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }
}
