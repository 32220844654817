import { Injectable } from "@angular/core";
import { CommonService } from "../../../common/common-services/common-service";
import { TokenStorage } from "../../../login/token.storage";

@Injectable({
  providedIn: "root",
})
export class RbsDalService {
  Parse: any;

  constructor(cm: CommonService, private token: TokenStorage) {
    this.Parse = cm.ParseJs;
  }

  getACLTasks() {
    const ACLTask = this.Parse.Object.extend("ACLTask");
    const query = new this.Parse.Query(ACLTask);
    query.include("taskType");
    query.limit(100000);
    query.include("taskSubType");
    query.limit(10000);
    return query.find().then((data) => {
      return data.map((aclTask) => {
        // console.log("aclTask",aclTask);
        const task = {
          taskId: aclTask.id,
          taskName: aclTask.attributes.name,
          taskDesc: aclTask.attributes.desc,
          taskTypeObj: aclTask.attributes.taskType,
          taskType: aclTask.attributes.taskType?.attributes.name || "NA",
          taskTypeId: aclTask.attributes.taskType?.id || "NA",
          taskSubType: aclTask.attributes.taskSubType?.attributes.name || "NA",
          taskSubTypeId: aclTask.attributes.taskSubType?.id || "NA",
          taskSubTypeObj: aclTask.attributes.taskSubType,
        };
        return task;
      });
    });
  }

  getACLRoles() {
    const Role = this.Parse.Object.extend("_Role");
    const query = new this.Parse.Query(Role);
    query.limit(1000);
    return query.find().then(
      (data) => {
        return data.map((roleObject) => {
          const role = {
            roleId: roleObject.id,
            roleName: roleObject.attributes.name,
          };
          return role;
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getACLTaskPermission() {
    const ACLTaskPermission = this.Parse.Object.extend("ACLTaskPermission");
    const query = new this.Parse.Query(ACLTaskPermission);
    query.limit(10000);
    return query.find().then((data) => {
      return data.map((tpObject) => {
        //console.log(tpObject);
        const tp = {
          taskId: tpObject.attributes.taskId.id,
          roleId: tpObject.attributes.roleId.id,
          isGranted: tpObject.attributes.isGranted,
        };
        return tp;
      });
    });
  }

  updateTaskPermission(taskId: string, roleId: string, isGranted: boolean) {
    const ACLTaskPermission = this.Parse.Object.extend("ACLTaskPermission");
    const ACLTask = this.Parse.Object.extend("ACLTask");
    const Role = this.Parse.Object.extend("_Role");

    const query = new this.Parse.Query(ACLTaskPermission);
    const querytask = new this.Parse.Query(ACLTask);
    const queryrole = new this.Parse.Query(Role);
    querytask.equalTo("objectId", taskId);
    queryrole.equalTo("objectId", roleId);
    query.limit(10000);
    query.matchesQuery("taskId", querytask);
    query.matchesQuery("roleId", queryrole);

    return query.find().then((data) => {
      // console.log(data);
      if (data.length <= 0) {
        //insert new object

        return querytask.get(taskId).then((task) => {
          return queryrole.get(roleId).then((role) => {
            const newPermission = new ACLTaskPermission();
            newPermission.set("taskId", task);
            newPermission.set("roleId", role);
            newPermission.set("isGranted", isGranted);
            return newPermission.save();
          });
        });
      } else {
        //update existing
        const objectId = data[0].id;
        const queryObject = new this.Parse.Query(ACLTaskPermission);
        return queryObject.get(objectId).then((obj) => {
          obj.set("isGranted", isGranted);
          return obj.save();
        });
      }
    });
  }

  isUserAllowedForActionsOffline(checkTasks: string[]): boolean {
    const allowedTasks = this.token.getAllowedActions();
    let isAllowed = false;
    checkTasks.forEach((task) => {
      if (allowedTasks.find((allowedTask) => allowedTask == task)) {
        //console.log(allowedTasks,true);
        isAllowed = true;
      }
    });
    return isAllowed;
  }

  isUserAllowedForActionsLive(allowedTask: string[]) {
    /*if any of the passed permission are true
    it will show the component/
    But if you wan to make it reverse then change in the task query
   */
    const currentuser = this.Parse.User.current();
    const Role = this.Parse.Object.extend("_Role");
    const ACLTask = this.Parse.Object.extend("ACLTask");
    const ACLTP = this.Parse.Object.extend("ACLTaskPermission");

    //for guest users not login user

    const roleguest = new this.Parse.Query(Role);
    roleguest.equalTo("name", "Guest");
    //roles of current user
    const userroles = new this.Parse.Query(Role);
    userroles.equalTo("users", currentuser);
    //anything that belong to any of it
    const rolequery = this.Parse.Query.or(roleguest, userroles);
    const taskquery = new this.Parse.Query(ACLTask);
    taskquery.containedIn("name", allowedTask);
    /* taskquery.find().then(tasks =>{
         console.log("tasks objects",tasks);
     });*/
    //fetch all Tasks with provided names
    const acltpquery = new this.Parse.Query(ACLTP);
    acltpquery.include("roleId");
    acltpquery.include("taskId");
    acltpquery.matchesQuery("roleId", rolequery);
    acltpquery.matchesQuery("taskId", taskquery);
    acltpquery.equalTo("isGranted", true);
    return acltpquery.find();
    /*.then((permissions)=>{
      if (permissions.length > 0){
        return true;
      }
      return
      //console.log(permissions);
     // if(permissions){
          permissions.forEach(p=>{
           // const pTask =p.attributes.taskId?.attributes.name;
            //const pRole =p.attributes.roleId?.attributes.name;
           // console.log("you are accessing it because permission ",pTask, " is granted to " , pRole)

       // });
      //}

    });*/
  }

  getLoginUserRoles() {
    const currentuser = this.Parse.User.current();
    const Role = this.Parse.Object.extend("_Role");
    const userroles = new this.Parse.Query(Role);
    userroles.equalTo("users", currentuser);
    return userroles.find();
  }

  getLoginUser() {
    return this.Parse.User.current();
  }

  getNewACLTask() {
    const ACLTask = this.Parse.Object.extend("ACLTask");
    return new ACLTask({});
  }

  getACLSubTypes() {
    const ACLTaskSubType = this.Parse.Object.extend("ACLTaskSubType");
    const subTypequery = new this.Parse.Query(ACLTaskSubType);
    return subTypequery.find();
  }

  async getTask(objectId: any) {
    const ACLTask = this.Parse.Object.extend("ACLTask");
    const taskquery = new this.Parse.Query(ACLTask);
    return taskquery.get(objectId);
  }

  getLoginUserAllowedActions() {
    const currentuser = this.Parse.User.current();
    const Role = this.Parse.Object.extend("_Role");
    const ACLTP = this.Parse.Object.extend("ACLTaskPermission");

    //for guest users not login user

    const roleguest = new this.Parse.Query(Role);
    roleguest.equalTo("name", "Guest");
    //roles of current user
    const userroles = new this.Parse.Query(Role);
    userroles.equalTo("users", currentuser);
    //anything that belong to any of it
    const rolequery = this.Parse.Query.or(roleguest, userroles);
    const acltpquery = new this.Parse.Query(ACLTP);
    acltpquery.limit(10000);
    acltpquery.include("roleId");
    acltpquery.limit(100000);
    acltpquery.include("taskId");
    acltpquery.matchesQuery("roleId", rolequery);
    acltpquery.equalTo("isGranted", true);
    return acltpquery.find();
  }
}
