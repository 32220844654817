import { Injectable, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { NbGlobalPhysicalPosition, NbToastrService } from "@nebular/theme";
import { Router } from "@angular/router";

declare const Parse: any;

@Injectable()
export class CommonService implements OnDestroy {
  client;

  private stationHealth = {
    filters: {
      columns: [
        {
          label: "SERIAL #",
          column: "deviceName",
          type: "text",
        },
        {
          label: "Failed Battery Count",
          column: "failedBatteryCount",
          type: "number",
        },
        {
          label: "Battery Percentage",
          column: "batteryPercentage",
          type: "number",
        },
        {
          label: "IMEI #",
          column: "boxCode",
          type: "text",
        },
        {
          label: "ADDRESS",
          column: "address",
          type: "pointer",
        },
        {
          label: "Charging Box Status",
          column: "chargingBoxStatus",
          type: "pointer",
        },
      ],
      pointers: ["address", "chargingBoxStatus"],
      ObjectNames: ["Address", "ChargingBoxStatus"],
      address: [
        {
          label: "LOCATION",
          column: "locationName",
          type: "text",
        },
      ],
      chargingBoxStatus: [
        {
          label: "STATUS",
          column: "status",
          type: "select",
        },
        {
          label: "SIM MNC",
          column: "SimOperatorByMnc",
          type: "text",
        },
        {
          label: "APK VERSION",
          column: "apkV",
          type: "text",
        },
        {
          label: "WIFI NETWORK",
          column: "currentWifi",
          type: "text",
        },
        {
          label: "CONNECTION TYPE",
          column: "connect",
          type: "text",
        },
        {
          label: "TOTAL SLOTS",
          column: "btAllN",
          type: "text",
        },
        {
          label: "BATTERIES INSIDE",
          column: "btOnlinN",
          type: "text",
        },
        {
          label: "SIM OPERATOR",
          column: "SimOperator",
          type: "text",
        },
        {
          label: "MAC ADDRESS",
          column: "macAddress",
          type: "text",
        },
        {
          label: "SIM CARD READINESS",
          column: "IsSimCardReady",
          type: "text",
        },
        {
          label: "DEVICE MANUFACTURER",
          column: "DeviceManufacturer",
          type: "text",
        },
        {
          label: "IoT HELPER READINESS",
          column: "IotHelperReady",
          type: "text",
        },
        {
          label: "IP ADDRESS",
          column: "IpAddress",
          type: "text",
        },
        {
          label: "DEVICE MODEL",
          column: "DeviceModel",
          type: "text",
        },
      ],
    },
    columnLabels: [
      {
        label: "LOCATION",
        childObjectName: "Address",
        childKey: "address",
        orderBy: "locationName",
        column: "chargingBox.attributes.address.attributes.locationName",
        type: "simple",
      },
      {
        label: "SERIAL #",
        type: "detail",
      },

      "IMEI #",
      "STATUS",
      {
        label: "LAST ONLINE",
        type: "date",
      },
      "UNITS",
      "APK VERSION",
      "ACTIVE RENTALS",
      "WIFI NETWORK",
      // 'MAC Address',
      "CONNECTION TYPE",
      "TOTAL SLOTS",
      "BATTERIES INSIDE",
      "BATTERIES PERCENTAGE",
      "FAILED BATTERIES",
      // 'Is Sim Card Ready',
      // 'Device Manufacturer',
      // 'Iot Helper Ready',
      // 'Network Type',
      // 'Android Version',
      // 'Ip Addr   ess',
      "SIM MNC",
      // 'Device Model',
      "SIM OPERATOR",
      "ADDRESS",
      // 'REQUESTED',
    ],
    columnsToDisplay: [
      // 'operation',
      "chargingBox.attributes.address.attributes.locationName",
      "chargingBox.attributes.deviceName",
      "chargingBox.attributes.boxCode",
      "chargingBoxStatus.attributes.status",
      "chargingBoxStatus.attributes.updatedAt",
      "chargingBoxStatus.attributes.type",
      "chargingBoxStatus.attributes.apkV",
      "activeRentals",
      "chargingBoxStatus.attributes.currentWifi",
      // 'chargingBoxStatus.attributes.macAddress',
      "chargingBoxStatus.attributes.connect",
      "chargingBoxStatus.attributes.btAllN",
      "chargingBoxStatus.attributes.btOnlinN",
      "batteryPercentage",
      "failedBatteryCount",
      // 'chargingBoxStatus.attributes.IsSimCardReady',
      // 'chargingBoxStatus.attributes.DeviceManufacturer',
      // 'chargingBoxStatus.attributes.IotHelperReady',
      // 'chargingBoxStatus.attributes.NetworkType',
      // 'chargingBoxStatus.attributes.AndroidVersion',
      // 'chargingBoxStatus.attributes.IpAddress',
      "chargingBoxStatus.attributes.SimOperatorByMnc",
      // 'chargingBoxStatus.attributes.DeviceModel',
      "chargingBoxStatus.attributes.SimOperator",
      "chargingBox.attributes.address.attributes.addressLine1",
    ],
    data: [],
    cloudFunction: "fetchAllStationsWithStaionHealth",
  };

  private partnerStation = {
    filters: {},
    columnLabels: [
      "select",
      // {
      //   label: "Location Name",
      //   type: "detail",
      // },
      "Location Name",
      "# Empty Slots",
      "# of rentals",
      "Location Revenue",
      "Station Status",
    ],
    columnsToDisplay: [
      "select",
      "locationName",
      "emptySlots",
      "numberOfRentals",
      "revenue",
      "status",
    ],
    data: [],
  };

  private bestPerformingLocations = {
    filters: {},
    columnLabels: [
      "select",
      // 'operations',
      // {label: 'Locations', detailKey: 'location', column: 'locationName'},
      "Location Name",
      "# Of Stations",
      "Rental Mins",
      //   'rentedDurationMinutesLessThan$40',
      // 'numberOfRentalsLessThan$40',
      // 'revenueLessThan$40',
      // 'rentedDurationMinutesGreaterThan$40',
      // 'numberOfRentalsGreaterThan$40',
      // 'revenueGreaterThan$40',
      "Rental Mins Less Than $40",
      "# Of Rentals Greater Than $40",
      "Revenue Greater Than $40",
      "Revenue",
      "# Of Rentals",
      "Stripe Fee",
    ],
    columnsToDisplay: [
      "select",
      // 'operations',
      "locationName",
      "numberOfStations",
      "rentedDurationMinutes",
      // 'rentedDurationMinutesLessThan$40',
      // 'numberOfRentalsLessThan$40',
      // 'revenueLessThan$40',
      // 'rentedDurationMinutesGreaterThan$40',
      // 'numberOfRentalsGreaterThan$40',
      // 'revenueGreaterThan$40',
      "rentedDurationMinutesLessThan40",
      "numberOfRentalsGreaterThan40",
      "revenueGreaterThan40",
      "revenue",
      "numberOfRentals",
      "totalStripeFee",
    ],
    data: [],
  };

  private bestPerformingStations = {
    filters: {},
    columnLabels: [
      "select",
      // {label: 'Stations', detailKey: 'station', column: 'stationName'},
      "Station Name",
      "Unit",
      "Rentals Mins",
      // 'rentedDurationMinutesLessThan$40',
      // 'numberOfRentalsLessThan$40',
      // 'revenueLessThan$40',
      // 'rentedDurationMinutesGreaterThan$40',
      // 'numberOfRentalsGreaterThan$40',
      // 'revenueGreaterThan$40',

      "Rental Mins Less Than $40",
      "# Of Rentals Greater Than $40",
      "Revenue Greater Than $40",
      "Revenue",
      "Number Of Rentals",
      "Stripe Fee",
    ],
    columnsToDisplay: [
      "select",
      "stationName",
      "type",
      "rentedDurationMinutes",
      // 'rentedDurationMinutesLessThan$40',
      // 'numberOfRentalsLessThan$40',
      // 'revenueLessThan$40',
      // 'rentedDurationMinutesGreaterThan$40',
      // 'numberOfRentalsGreaterThan$40',
      // 'revenueGreaterThan$40',

      "rentedDurationMinutesLessThan40",
      "numberOfRentalsGreaterThan40",
      "revenueGreaterThan40",
      "revenue",
      "numberOfRentals",
      "totalStripeFee",
    ],
    data: [],
  };

  constructor(private toastrService: NbToastrService, private router: Router) {
    Parse.initialize(environment.PARSE_APP_ID);
    Parse.serverURL = environment.serverURL;
    // if (this.client) this.closeLiveQueryClient();
    // setTimeout(() => {
    //   const LiveQueryClient = Parse.LiveQueryClient;
    //   this.client = new LiveQueryClient({
    //     applicationId: environment.PARSE_APP_ID,
    //     serverURL: environment.PARSE_WSS_URL
    //   });
    //   this.client.open();
    // }, 2000);
  }

  ngOnDestroy() {
    this.closeLiveQueryClient();
  }

  get ParseJs() {
    return Parse;
  }

  get getLiveQueryClient() {
    return this.client;
  }

  closeLiveQueryClient() {
    // return this.client.close();
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

  public getBestPerformingLocations() {
    return this.bestPerformingLocations;
  }

  public getStationHealth() {
    return this.stationHealth;
  }

  public getPartnerStation() {
    return this.partnerStation;
  }

  public getBestPerformingStations() {
    return this.bestPerformingStations;
  }

  getParse(objectName, pointers) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.limit(100000);
    query.descending("createdAt");
    pointers.map((pointer) => {
      query.include(pointer.relationName);
    });
    return query.find();
  }

  getParseDataById(objectName, id, pointers?) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    if (pointers)
      pointers.map((pointer) => {
        query.include(pointer.relationName);
      });
    return query.get(id);
  }

  getParseFindByIds(objectName, ids) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.containedIn("objectId", ids);
    return query.find("objectId", ids);
  }

  getParseFind(objectName, fieldName, fieldValue) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.equalTo(fieldName, fieldValue);
    return query.find();
  }

  getParseData(result, pointers) {
    const temp: any = result.toJSON();
    pointers.map((pointer) => {
      if (temp[pointer.relationName]) {
        temp[pointer.relationName].object =
          result.attributes[pointer.relationName];
      }
    });
    return temp;
  }

  formatResult(result, id?) {
    const temp = { ...result.attributes };
    temp[id] = result.id;
    return temp;
  }

  getOptionsData(results, key) {
    const arr = [];
    results.map((result) => {
      let temp: any = {};
      temp = { ...temp, ...this.formatResult(result, key) };
      temp.object = result;
      arr.push(temp);
    });
    return arr;
  }

  postParseData(data, parentForm, objectName) {
    const ObjectParent = Parse.Object.extend(objectName);
    const objectParent = new ObjectParent();
    // const keys = Object.keys(data);
    parentForm.map((formObj) => {
      objectParent.set(formObj.key, data[formObj.key]);
    });
    return objectParent.save();
  }

  cloudFunction(funcName, params) {
    return Parse.Cloud.run(funcName, params);
  }

  showNotification(type, message) {
    const config = {
      status: type,
      destroyByClick: false,
      duration: 5000,
      hasIcon: false,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    this.toastrService.show("", message, config);
  }

  getStations() {
    const Product = Parse.Object.extend("ChargingBox");
    const query = new Parse.Query(Product);
    query.notEqualTo("deviceName", "");
    query.limit(100000);
    return query.find();
  }

  getStationsConverted() {
    const ChargingBox = Parse.Object.extend("ChargingBox");
    const query = new Parse.Query(ChargingBox);
    query.notEqualTo("deviceName", "");
    query.include("product");
    query.include("apk");
    query.include("type");
    query.include("address");
    query.limit(100000);
    return query.find().then((data) => {
      return data.map((parse_station) => {
        const station = {
          objectId: parse_station.id,
          deviceName: parse_station.attributes.deviceName || null,
          locationName:
            parse_station.attributes.address?.attributes.locationName || null,
          boxCode: parse_station.attributes.boxCode,
          status: parse_station.attributes.status || null,
          apkVersion:
            parse_station.attributes.apk?.attributes.apkVersion || null,
          type: parse_station.attributes.type?.attributes.name || null,
        };
        return station;
      });
    });
  }

  getStationsAssignedConverted(locationId: string) {
    //retrive all station those are assigned to some sepecific location.

    const ChargingBox = Parse.Object.extend("ChargingBox");
    const Address = Parse.Object.extend("Address");

    const query = new Parse.Query(ChargingBox);
    const queryAddress = new Parse.Query(Address);
    queryAddress.equalTo("objectId", locationId);

    query.notEqualTo("deviceName", "");
    query.include("product");
    query.include("apk");
    query.include("type");
    query.matchesQuery("address", queryAddress);
    query.limit(100000);
    return query.find().then((data) => {
      return data.map((parse_station) => {
        const station = {
          objectId: parse_station.id,
          deviceName: parse_station.attributes.deviceName || null,
          locationName:
            parse_station.attributes.address?.attributes.locationName || null,
          boxCode: parse_station.attributes.boxCode,
          status: parse_station.attributes.status || null,
          apkVersion:
            parse_station.attributes.apk?.attributes.apkVersion || null,
          type: parse_station.attributes.type?.attributes.name || null,
        };
        return station;
      });
    });
  }

  goToUrl(path) {
    this.router.navigate([path]);
  }
}
