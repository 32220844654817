<div class="header-container">
  <div class="logo-container">
    <a class="logo" routerLink="dashboard/home"
    ><img
      style="height: 30px"
      src="https://uploads-ssl.webflow.com/614faa1a6eb1ae82d3221592/616e21a727a7ec0cbc5f8f68_Asset%201.svg"
    /></a>
  </div>
</div>

<div class="header-container">
  <a (click)="logout()">
    <svg
      class=""
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="LogoutIcon"
      tabindex="-1"
      title="Logout"
      style="height: 30px"
    >
      <path
        d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
      ></path>
    </svg>
  </a>
</div>
