import { Injectable } from "@angular/core";

const TOKEN_KEY = "AuthToken";
const USER_ALLOWED_ACTIONS = "User_Allowed_Actions";

@Injectable()
export class TokenStorage {
  signOut() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_ALLOWED_ACTIONS);
    window.localStorage.clear();
  }

  saveToken(token: string) {
    if (!token) return;
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  saveAllowedActions(allowedActions) {
    window.localStorage.removeItem(USER_ALLOWED_ACTIONS);
    window.localStorage.setItem(USER_ALLOWED_ACTIONS, allowedActions);
  }

  getAllowedActions() {
    let allowedActions = [];

    if (localStorage.getItem(USER_ALLOWED_ACTIONS)) {
      allowedActions = localStorage.getItem(USER_ALLOWED_ACTIONS).split(",");
    }
    return allowedActions;
  }

  saveRoleType(role) {
    window.localStorage.removeItem("role");
    window.localStorage.setItem("role", role);
  }

  get RoleType() {
    return localStorage.getItem("role");
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }
}
