import { Injectable } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ConfirmationPopup } from "./confirmation-popup";
import { NbDialogRef } from "@nebular/theme/components/dialog/dialog-ref";

@Injectable()
export class ConfirmationPopupService {
  constructor(private dialogService: NbDialogService) {}

  openConfirmationPopup(msg?): NbDialogRef<ConfirmationPopup> {
    const message = "Are you sure want to delete?";
    return this.dialogService.open(ConfirmationPopup, {
      context: {
        config: {
          message: msg ? msg : message,
          confirmBtnText: "Yes",
          cancelBtnText: "No",
        },
      },
    });
  }
}
