import { Component } from "@angular/core";
import { NbMenuItem } from "@nebular/theme";
import { RbsDalService } from "./rbs-home/services/rbs-dal.service";

@Component({
  selector: "ngx-role-base-dashboard",
  templateUrl: "./role-base-dashboard.component.html",
  styleUrls: ["./role-base-dashboard.component.scss"],
})
export class RoleBaseDashboardComponent {
  menu: NbMenuItem[] = [
    {
      title: "Add role to user",
      link: "/role-base/add-user-to-role",
    },
    {
      // path:'rbs-task-add rbs-task-home rbs-tester rbs-manager',
      title: "Add task",
      link: "/role-base/rbs-task-add",
    },
    {
      title: "Task home",
      link: "/role-base/rbs-task-home",
    },
    {
      title: "Tester",
      link: "/role-base/rbs-tester",
    },
    {
      title: "Manager",
      link: "/role-base/rbs-manager",
    },
    {
      title: "Salesperson Manager",
      link: "/role-base/rg-manager",
    },
    {
      title: "Partner Manager",
      link: "/role-base/partner-manager",
    },
    {
      title: "Distributor Manager",
      link: "/role-base/distributor-manager",
    },
    {
      title: "User Manager",
      link: "/role-base/user-manager",
    },
  ];

  constructor(private rbs: RbsDalService) {}
}
